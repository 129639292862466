import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import AnchorLink from 'react-anchor-link-smooth-scroll';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className="cmp-toc" id="nav">
  <div className="obj-max-width">
    <h2 className="util-font-h4 util-text-center">Table of Contents</h2>
    <hr className="cmp-hr-aqua util-margin-vertical-lg" />
  </div>
  <nav className="cmp-nav">
    <AnchorLink href="#respondents" className="cmp-nav__link cmp-fade-up js-watch" data-animate="cmp-fade-up--animate" mdxType="AnchorLink">
      <div aria-hidden="true" className="cmp-icon cmp-icon--respondents util-gradient-blue-to-violet"></div>
      <div>The Respondents</div>
    </AnchorLink>
    <AnchorLink href="#in-house" className="cmp-nav__link cmp-fade-up js-watch" data-animate="cmp-fade-up--animate" mdxType="AnchorLink">
      <div aria-hidden="true" className="cmp-icon cmp-icon--in-house util-gradient-violet-to-pink"></div>
      <div>In-House Design Systems</div>
    </AnchorLink>
    <AnchorLink href="#challenges" className="cmp-nav__link cmp-fade-up js-watch" data-animate="cmp-fade-up--animate" mdxType="AnchorLink">
      <div aria-hidden="true" className="cmp-icon cmp-icon--challenges util-gradient-pink-to-orange"></div>
      <div>Design System Challenges</div>
    </AnchorLink>
    <AnchorLink href="#maturity" className="cmp-nav__link cmp-fade-up js-watch" data-animate="cmp-fade-up--animate" mdxType="AnchorLink">
      <div aria-hidden="true" className="cmp-icon cmp-icon--maturity util-gradient-orange-to-yellow"></div>
      <div>Design System Maturity</div>
    </AnchorLink>
    <AnchorLink href="#culture" className="cmp-nav__link cmp-fade-up js-watch" data-animate="cmp-fade-up--animate" mdxType="AnchorLink">
      <div aria-hidden="true" className="cmp-icon cmp-icon--culture util-gradient-yellow-to-green"></div>
      <div>Positive Culture: A&nbsp;Lasting Impact</div>
    </AnchorLink>
    <AnchorLink href="#conclusion" className="cmp-nav__link cmp-fade-up js-watch" data-animate="cmp-fade-up--animate" mdxType="AnchorLink">
      <div aria-hidden="true" className="cmp-icon cmp-icon--conclusion util-gradient-green-to-blue"></div>
      <div>Conclusion</div>
    </AnchorLink>
  </nav>
  <div className="obj-max-width">
    <hr className="cmp-hr-aqua util-margin-vertical-lg" />
  </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      