import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2020/section-header";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SectionHeader title="In-House Design Systems" name="in-house" mdxType="SectionHeader">
      <p>{`Learn how in-house respondents manage their design systems and deliver them to consumers.`}</p>
    </SectionHeader>
    <div className="obj-max-width util-pad-vertical-lg util-pad-vertical-h1@md util-pad-vertical-h2@lg">
      <h2>{`Design Systems Are Getting Older`}</h2>
      <p>{`Most in-house respondents have had their design systems for three or fewer years (76%), though some have had their design systems for five or more (6%).`}</p>
      <h3>{`Approximately how long has your design system existed?`}</h3>
      <div className="cmp-chart-table">
  <table>
    <caption className="util-visually-hidden">Approximately how long has your design system existed?</caption>
    <tbody>
      <tr>
        <th>Less Than 1 Year</th>
        <td>25%</td>
      </tr>
      <tr>
        <th>1 Year</th>
        <td>12%</td>
      </tr>
      <tr>
        <th>2 Years</th>
        <td>24%</td>
      </tr>
      <tr>
        <th>3 Years</th>
        <td>14%</td>
      </tr>
      <tr>
        <th>4 Years</th>
        <td>6%</td>
      </tr>
      <tr>
        <th>5 Years</th>
        <td>1%</td>
      </tr>
      <tr>
        <th>More Than 5 Years</th>
        <td>5%</td>
      </tr>
      <tr>
        <th>I Don't Know</th>
        <td>2%</td>
      </tr>
    </tbody>
  </table>
      </div>
  
      <div aria-hidden="true" className="cmp-chart-section cmp-chart-section--is-animated js-watch" data-animate="cmp-chart-section--is-animating">
  <div className="cmp-chart-range">
    <div className="cmp-chart-range__value">0%</div>
    <div className="cmp-chart-range__value">10%</div>
    <div className="cmp-chart-range__value">20%</div>
    <div className="cmp-chart-range__value">30%</div>
  </div>
  <dl className="cmp-chart" style={{
          "-Range": "30"
        }}>
    <div className="cmp-chart__group"> 
      <dt className="cmp-chart__title">Less Than 1 Year</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data " style={{
                "-Val": "25"
              }}>
          <span className="cmp-chart__data-text">25%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">1 Year</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "12"
              }}>
          <span className="cmp-chart__data-text">12%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">2 Years</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "24"
              }}>
          <span className="cmp-chart__data-text">24%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">3 Years</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "14"
              }}>
          <span className="cmp-chart__data-text">14%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">4 Years</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "6"
              }}>
          <span className="cmp-chart__data-text">6%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">5 Years</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "1"
              }}>
          <span className="cmp-chart__data-text cmp-chart__data-text--outside">1%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">More Than 5 Years</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "5"
              }}>
          <span className="cmp-chart__data-text">5%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">I Don't Know</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "2"
              }}>
          <span className="cmp-chart__data-text cmp-chart__data-text--outside">2%</span>
        </span>
      </dd>
    </div>
  </dl>
      </div>
      <div className="cmp-answer">
  <p className="cmp-answer__badge">
    <span className="cmp-answer__label">Answered</span>
    <span className="cmp-answer__count">155</span>
  </p>
      </div>
      <div className="cmp-aside">
  <h3 className="cmp-aside__heading">Fun fact!</h3>
  <p className="cmp-aside__text cmp-aside__text--large cmp-aside__text--no-margin">69% of respondents have given their design system a name.</p>
      </div>
      <hr></hr>
      <h2>{`Design Systems Have Similar Elements`}</h2>
      <p>{`In-house respondent were asked to select the elements their design system contained from a list of 19 items and could write in other answers. Over 50% of in-house respondents reported their design system included the following elements—with color systems appearing in 95% of the design systems.`}</p>
      <h3>{`What does your design system contain?`}</h3>
      <div className="util-font-p-big">
        <ol>
          <li parentName="ol"><strong parentName="li">{`Color system (95%)`}</strong></li>
          <li parentName="ol"><strong parentName="li">{`Typography system (85%)`}</strong></li>
          <li parentName="ol"><strong parentName="li">{`Form components (78%)`}</strong></li>
        </ol>
      </div>
      <div className="cmp-list-split">
        <ol start="4">
  <li>Navigation components (76%)</li>
  <li>Spacing system (72%)</li>
  <li>Usage guidelines (71%)</li>
  <li>Grid system (65%)</li>
  <li>Framework-specific components (React, Angular, etc) (61%)</li>
  <li>Accessibility guidelines (60%)</li>
  <li>Design files (59%)</li>
  <li>CSS code (59%)</li>
  <li>Brand guidelines (58%)</li>
  <li>HTML code (53%)</li>
  <li>JavaScript code (53%)</li>
  <li>Layout system (52%)</li>
        </ol>
      </div>
      <hr></hr>
      <h2>{`Design Systems Are More Than Just a Reference`}</h2>
      <p>{`The majority of in-house respondents answered that they have integrated their design system into the codebase in some fashion rather than using it as a standalone reference tool.`}</p>
      <h3>{`How is your design system primarily delivered to the consumers of the design system?`}</h3>
      <div className="cmp-chart-table">
  <table>
    <caption className="util-visually-hidden">How is your design system primarily delivered to the consumers of the design system?</caption>
    <tbody>
      <tr>
        <th>In an external codebase and consumed via package manager (npm, gem, composer, etc.)</th>
        <td>35%</td>
      </tr>
        <tr>
        <th>Included in the main codebase</th>
        <td>20%</td>
      </tr>
        <tr>
        <th>On a standalone documentation site where users primarily copy + paste or download files</th>
        <td>20%</td>
      </tr>
        <tr>
        <th>In an external codebase and consumed by the main codebase(s') build pipeline</th>
        <td>11%</td>
      </tr>
        <tr>
        <th>I am not sure</th>
        <td>10%</td>
      </tr>
        <tr>
        <th>Other</th>
        <td>5%</td>
      </tr>
    </tbody>
  </table>
      </div>
      <div aria-hidden="true" className="cmp-chart-section cmp-chart-section--is-animated js-watch" data-animate="cmp-chart-section--is-animating">
  <div className="cmp-chart-range">
    <div className="cmp-chart-range__value">0%</div>
    <div className="cmp-chart-range__value">10%</div>
    <div className="cmp-chart-range__value">20%</div>
    <div className="cmp-chart-range__value">30%</div>
    <div className="cmp-chart-range__value">40%</div>
  </div>
  <dl className="cmp-chart" style={{
          "-Range": "40"
        }}>
    <div className="cmp-chart__group"> 
      <dt className="cmp-chart__title">In an external codebase and consumed via package manager (npm, gem, composer, etc.)</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "35"
              }}>
          <span className="cmp-chart__data-text">35%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Included in the main codebase</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "20"
              }}>
          <span className="cmp-chart__data-text">20%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">On a standalone documentation site where users primarily copy + paste or download files</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "20"
              }}>
          <span className="cmp-chart__data-text">20%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">In an external codebase and consumed by the main codebase(s') build pipeline</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "11"
              }}>
          <span className="cmp-chart__data-text">11%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">I am not sure</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "10"
              }}>
          <span className="cmp-chart__data-text">10%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Other</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "5"
              }}>
          <span className="cmp-chart__data-text">5%</span>
        </span>
      </dd>
    </div>
  </dl>
      </div>
      <div className="cmp-answer">
  <p className="cmp-answer__badge">
    <span className="cmp-answer__label">Answered</span>
    <span className="cmp-answer__count">130</span>
  </p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      