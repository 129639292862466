import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import NewsletterForm from "../../components/2020/newsletter-form";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="obj-content-width util-pad-bottom-lg util-pad-bottom-h1@md util-pad-bottom-h2@lg">
  <h2 className="util-font-h2 util-text-center">Sign up for our&nbsp;newsletter</h2>
  <p className="util-font-p util-text-center">Sign up to receive Sparkbox Newsletters in your inbox, which includes a monthly recap of what’s new on <a href="https://sparkbox.com/foundry">The&nbsp;Foundry</a>.</p>
  <NewsletterForm mdxType="NewsletterForm" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      