import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <header className="cmp-site-header" id="top">
  <h1 className="util-visually-hidden">2020 Design Systems Survey</h1>
  <div className="obj-max-width util-pad-vertical-lg util-pad-vertical-h2@md util-pad-vertical-h3@lg">
    <h2 className="cmp-site-header__sbx cmp-fade-left js-watch" data-animate="cmp-fade-left--animate">
      <a href="https://sparkbox.com" className="cmp-site-header__sbx-link">
        <span className="util-visually-hidden">From your friends at Sparkbox.</span>
      </a>
    </h2>
    <div className="cmp-site-header__container">
      <div className="cmp-site-header__link util-pad-vertical-lg cmp-fade-in js-watch" data-animate="cmp-fade-in--animate">
        <p><a href="https://designsystemssurvey.seesparkbox.com/">Check out the results from the latest Design Systems Survey</a></p>
      </div>
      <div aria-hidden="true" className="cmp-site-header__icon cmp-fade-up js-watch" data-animate="cmp-fade-up--animate">
        <div className="cmp-icon cmp-icon--chart cmp-icon--hero"></div>
      </div>
      <div aria-hidden="true" className="cmp-site-header__title cmp-fade-in js-watch" data-animate="cmp-fade-in--animate">
        2020 Design Systems&nbsp;Survey
      </div>
      <div className="cmp-site-header__content cmp-fade-in js-watch" data-animate="cmp-fade-in--animate">
        <p>The 2020 Design Systems Survey reveals the lasting change that a successful design system can have on an organization. These results explore the challenges of having a design system that is built to last, from planning and managing changes to organizational adoption. These results also delve into what makes a design system mature and how these mature design systems impact the teams who use them.</p>
        <p>By examining these perspectives from people who work directly with design systems, we hope to provide guidance to those looking to start or grow a design system that will last for years.</p>
      </div>
    </div>
  </div>
    </header>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      