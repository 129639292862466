import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2020/section-header";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SectionHeader title="Positive Culture Changes: A Lasting Impact" name="culture" mdxType="SectionHeader">
      <p>{`Both in-house and agency respondents highlighted how a design system can create positive cultural change.`}</p>
    </SectionHeader>
    <div className="obj-max-width util-pad-vertical-lg util-pad-vertical-h1@md util-pad-vertical-h2@lg">
      <h2>{`Design Systems Are Creating Positive Change`}</h2>
      <p className="util-font-p-big">When in-house respondents were asked if their design system contributed to a cultural change, 78% of respondents acknowledge a positive change in their culture.</p>
      <p>{`In an open-ended question, respondents were asked to describe the cultural change. The following were most frequently mentioned:`}</p>
      <ol>
        <li parentName="ol">{`Better collaboration and cross-team collaboration (14 of 26 respondents)`}</li>
        <li parentName="ol">{`The team was allied around the importance of a unified system (8 of 26 respondents)`}</li>
        <li parentName="ol">{`Shared common processes and consistency (6 of 26 respondents) `}</li>
      </ol>
      <blockquote>
        <p parentName="blockquote">{`“Contributed to better collaboration between designers and developers, since they have more of a shared vocabulary and can work more on valuable problem solving.”`}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`“The design system acts as a rosetta stone that fosters a common language between designer and developer.”`}</p>
      </blockquote>
      <p>{`Other cultural changes mentioned by three or more in-house respondents were improved efficiency and the inclusion of accessibility practices.`}</p>
      <hr></hr>
      <h2>{`Even Agencies See the Change within Client Teams`}</h2>
      <p className="util-font-p-big">Agency respondents also reported a cultural impact. When asked, 54% said they saw a client’s culture change due to the introduction of a design system.</p>
      <p>{`In an open-ended question about the changes they have seen, respondents answered with the following top answers:`}</p>
      <ol>
        <li parentName="ol">{`Better communication and collaboration among their client’s teams (6 of 13 respondents)`}</li>
        <li parentName="ol">{`Consistency with the design system being a single source of truth (4 of 13 respondents)`}</li>
      </ol>
      <blockquote>
        <p parentName="blockquote">{`“Due to the nature of a design system requiring buy-in and support from multiple disciplines, a design system project often helps break down organizational silos.”`}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`“Better communication between product owners, UI/UX designers, and front-end engineers. We all have a single source of truth.”`}</p>
      </blockquote>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      