import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2020/section-header";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SectionHeader title="Conclusion" name="conclusion" mdxType="SectionHeader">
      <p>{`These survey results show that creating and maintaining an impactful design system comes with challenges such as planning a clear strategy, managing changes to the system, and fostering design system adoption across the organization. Yet the long-lasting value of a mature design system—like collaboration and better communication—awaits after the hard work of overcoming these challenges is done.`}</p>
    </SectionHeader>
    <div className="obj-max-width util-pad-top-lg util-pad-top-h1@md util-pad-top-h2@lg">
      <h2>{`Learn more about building, using, and maintaining design systems from the experts at Sparkbox.`}</h2>
      <p><a href="https://sparkbox.com/">Sparkbox</a> has become an expert in design systems and works to continue helping others by creating content about <a href="https://sparkbox.com/foundry/category/design_systems">design systems</a>.</p>
      <p>{`Are you looking for help building, adjusting, or maintaining your design system? `}<a parentName="p" {...{
          "href": "https://sparkbox.com/contact"
        }}>{`Reach out to us for more information on how we can help`}</a>{`!`}</p>
      <p>{`Or are you interested in the full data set of this survey to run your own analysis? `}<a parentName="p" {...{
          "href": "https://www.dropbox.com/s/7bjji6e1iogv7ta/2020%C2%A0Design%20Systems%20Survey%20Full%20Results.numbers?dl=0"
        }}>{`Download the file on Dropbox`}</a>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      