import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionHeader from "../../components/2020/section-header";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SectionHeader title="Design System Challenges" name="challenges" mdxType="SectionHeader">
      <p>{`In the `}<a parentName="p" {...{
          "href": "https://designsystemssurvey.seesparkbox.com/2018/#why-design-systems-need-champions"
        }}>{`2018 survey`}</a>{`, we found that starting a design system required an executive champion and cross-departmental buy-in. In the `}<a parentName="p" {...{
          "href": "https://designsystemssurvey.seesparkbox.com/2019/#section-3"
        }}>{`2019 survey`}</a>{`, we found that adoption and maintenance were common challenges. This section explores challenges uncovered in this year’s survey.`}</p>
    </SectionHeader>
    <div className="obj-max-width util-pad-vertical-lg util-pad-vertical-h1@md util-pad-vertical-h2@lg">
      <h2>{`This year, respondents shared challenges that span throughout the life of a design system. Three challenges stood out:`}</h2>
      <div className="util-font-size-lg">
  <ol>
    <li>Planning a design system that is built to last</li>
    <li>Managing changes to the design system</li>
    <li>Encouraging organizational adoption </li>
  </ol>
      </div>
      <hr></hr>
      <h2>{`Planning a Design System That Is Built to Last`}</h2>
      <div className="cmp-aside cmp-aside--float cmp-fade-right js-watch" data-animate="cmp-fade-right--animate">
  <p className="cmp-aside__text cmp-aside__text--callout">In-house and agency respondents frequently mentioned needing a better plan and a more well-thought-out strategy.</p>
      </div>
      <p>{`In-house respondents were asked this open-ended question: if you had the ability to go back in time, what would you do differently with your design system? The most common in-house response mentioned having a better plan and a more well-thought-out strategy (18 out of 42 responses).`}</p>
      <p>{`When agency respondents were asked why a client’s design system failed, they commonly reported the lack of a well-thought-out strategy (5 of 16 respondents).`}</p>
      <h3>{`Is your design system creating technical debt?`}</h3>
      <p>{`In addition, 42% of in-house respondents felt that the way their design system was originally built created debt for the organization’s technical or design departments.`}</p>
      <p>{`When we asked how building the design system created debt, the two top responses were both related to a planning failure:`}</p>
      <ol>
        <li parentName="ol">{`Lack of planning (9 of 23 respondents)`}</li>
        <li parentName="ol">{`Failure to understand the magnitude of a design system (8 of 23 respondents)`}</li>
      </ol>
      <h3>{`Takeaway: Planning supports success`}</h3>
      <p>{`If hindsight is truly 20/20, planning and developing a clear strategy early in a design system’s life could contribute to its long-term success.`}</p>
      <div className="cmp-aside">
  <h3 className="cmp-aside__heading">Are you planning a design system?</h3>
  <p className="cmp-aside__text">Learn how a <a href="https://sparkbox.com/foundry/design_system_planning_project_discovery_phase">Discovery phase</a> can help you identify key details to make your design system a success. Or learn how to do <a href="https://sparkbox.com/foundry/design_audit_for_design_system_planning">an audit to decomp your website</a> and identify and organize elements for future use.</p>
      </div>
      <hr></hr>
      <h2>{`Managing Changes to the Design System`}</h2>
      <h3>{`What teams have maintenance processes?`}</h3>
      <p>{`In general, 63% of in-house respondents have a process for maintaining outdated, unused, or faulty components in their design systems. The age of the system impacted the likelihood to report having a maintenance process:`}</p>
      <ul>
        <li parentName="ul">{`Only 44% of respondents with a design system less than one year old have a maintenance process.`}</li>
        <li parentName="ul">{`For respondents with design systems that have existed for one year or longer, the percentage with a maintenance process grows to 77%.`}</li>
      </ul>
      <p>{`The likelihood of having a process was also higher for those in-house respondents who called their design systems successful.`}</p>
      <h3>{`Does your organization have a process for maintaining design system components?`}</h3>
      <div className="cmp-chart-table">
  <table>
    <caption className="util-visually-hidden">Does your organization have a process for maintaining design system components?</caption>
    <thead>
      <tr>
        <th></th>
        <th>Yes, we have a process</th>
        <th>No, we don't have a process</th>
        <th>I don't know</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>Not Very Successful Design System</th>
        <td>33%</td>
        <td>67%</td>
        <td>0%</td>
      </tr>
      <tr>
        <th>Not Successful Design System</th>
        <td>50%</td>
        <td>50%</td>
        <td>0%</td>
      </tr>
      <tr>
        <th>Neutral Design System</th>
        <td>56%</td>
        <td>38%</td>
        <td>6%</td>
      </tr>
      <tr>
        <th>Successful Design System</th>
        <td>78%</td>
        <td>11%</td>
        <td>11%</td>
      </tr>
      <tr>
        <th>Very Successful Design System</th>
        <td>100%</td>
        <td>0%</td>
        <td>0%</td>
      </tr>
    </tbody>
  </table>
      </div>
      <div aria-hidden="true" className="cmp-chart-section cmp-chart-section--is-animated js-watch" data-animate="cmp-chart-section--is-animating">
  <div className="cmp-chart-key">
    <div className="cmp-chart-key__item">Yes, we have a process</div>
    <div className="cmp-chart-key__item">No, we don't have a process</div>
    <div className="cmp-chart-key__item">I don't know</div>
  </div>
  <div className="cmp-chart-range">
    <div className="cmp-chart-range__value">0%</div>
    <div className="cmp-chart-range__value">25%</div>
    <div className="cmp-chart-range__value">50%</div>
    <div className="cmp-chart-range__value">75%</div>
    <div className="cmp-chart-range__value">100%</div>
  </div>
  <div className="cmp-chart" style={{
          "-Range": "100"
        }}>
    <div className="cmp-chart__group">
      <div className="cmp-chart__title">Not Very Successful Design System</div>
      <div className="cmp-chart__range cmp-chart__range--no-bg">
        <span className="cmp-chart__data" style={{
                "-Val": "33"
              }}>
          <span className="cmp-chart__data-text">33%</span>
        </span>
        <span className="cmp-chart__data" style={{
                "-Val": "67"
              }}>
          <span className="cmp-chart__data-text">67%</span>
        </span>
      </div>
    </div>
    <div className="cmp-chart__group">
      <div className="cmp-chart__title">Not Successful Design System</div>
      <div className="cmp-chart__range cmp-chart__range--no-bg">
        <span className="cmp-chart__data" style={{
                "-Val": "50"
              }}>
          <span className="cmp-chart__data-text">50%</span>
        </span>
        <span className="cmp-chart__data" style={{
                "-Val": "50"
              }}>
          <span className="cmp-chart__data-text">50%</span>
        </span>
      </div>
    </div>
    <div className="cmp-chart__group">
      <div className="cmp-chart__title">Neutral Design System</div>
      <div className="cmp-chart__range cmp-chart__range--no-bg">
        <span className="cmp-chart__data" style={{
                "-Val": "56"
              }}>
          <span className="cmp-chart__data-text">56%</span>
        </span>
        <span className="cmp-chart__data" style={{
                "-Val": "38"
              }}>
          <span className="cmp-chart__data-text">38%</span>
        </span>
        <span className="cmp-chart__data cmp-chart__data--small" style={{
                "-Val": "6"
              }}>
          <span className="cmp-chart__data-text">6%</span>
        </span>
      </div>
    </div>
    <div className="cmp-chart__group">
      <div className="cmp-chart__title">Successful Design System</div>
      <div className="cmp-chart__range cmp-chart__range--no-bg">
        <span className="cmp-chart__data" style={{
                "-Val": "77"
              }}>
          <span className="cmp-chart__data-text">78%</span>
        </span>
        <span className="cmp-chart__data" style={{
                "-Val": "11.5"
              }}>
          <span className="cmp-chart__data-text">11%</span>
        </span>
        <span className="cmp-chart__data" style={{
                "-Val": "11.5"
              }}>
          <span className="cmp-chart__data-text">11%</span>
        </span>
      </div>
    </div>
    <div className="cmp-chart__group">
      <div className="cmp-chart__title">Very Successful Design System</div>
      <div className="cmp-chart__range cmp-chart__range--no-bg">
        <span className="cmp-chart__data" style={{
                "-Val": "100"
              }}>
          <span className="cmp-chart__data-text">100%</span>
        </span>
      </div>
    </div>
  </div>
      </div>
      <div className="cmp-answer">
  <p className="cmp-answer__badge">
    <span className="cmp-answer__label">Answered</span>
    <span className="cmp-answer__count">105</span>
  </p>
      </div>
      <div className="obj-has-float util-margin-vertical-lg util-margin-vertical-h1@lg">
  <div className="cmp-aside cmp-aside--float cmp-fade-right js-watch" data-animate="cmp-fade-right--animate">
    <p className="cmp-aside__text cmp-aside__text--callout">Of the in-house respondents who reported their design systems to be <em>unsuccessful</em>, only 46% had a maintenance process.</p>
    <p className="cmp-aside__text cmp-aside__text--callout">Of the respondents who reported their design systems to be <em>successful</em>, 81% had a maintenance process.</p>
  </div>
        <p>{`Of the respondents who reported their design systems to be successful or very successful, 81% had a maintenance process. And that same group of respondents also viewed their change approval process as successful.`}</p>
        <p>{`Of the in-house respondents who reported their design systems to be not successful or not very successful, only 46% had a maintenance process. Within this percentage, 55% of respondents perceived their maintenance process as unsuccessful.`}</p>
        <p>{`Perhaps recognizing the complexity and importance in maintaining a design system, 89% of in-house respondents answered they rely on a single team and/or multiple teams to influence and approve changes to the design system.`}</p>
      </div>
      <h3>{`Who approves changes to the design system?`}</h3>
      <div className="cmp-chart-table">
  <table>
    <caption className="util-visually-hidden">Who approves changes to the design system?</caption>
    <tbody>
      <tr>
        <th>A single team is responsible for approving changes.</th>
        <td>48%</td>
      </tr>
      <tr>
        <th>A combination of a single team that manages the design system and a committee that influences decisions.</th>
        <td>25%</td>
      </tr>
      <tr>
        <th>A cross-team committee is responsible for approving changes.</th>
        <td>16%</td>
      </tr>
      <tr>
        <th>One individual oversees the design system.</th>
        <td>6%</td>
      </tr>
      <tr>
        <th>We don’t have a standardized process for this.</th>
        <td>3%</td>
      </tr>
      <tr>
        <th>Other</th>
        <td>1%</td>
      </tr>
    </tbody>
  </table>
      </div>
      <div aria-hidden="true" className="cmp-chart-section cmp-chart-section--is-animated js-watch" data-animate="cmp-chart-section--is-animating">
  <div className="cmp-chart-range">
    <div className="cmp-chart-range__value">0%</div>
    <div className="cmp-chart-range__value">10%</div>
    <div className="cmp-chart-range__value">20%</div>
    <div className="cmp-chart-range__value">30%</div>
    <div className="cmp-chart-range__value">40%</div>
    <div className="cmp-chart-range__value">50%</div>
  </div>
  <dl className="cmp-chart" style={{
          "-Range": "50"
        }}>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">A single team is responsible for approving changes.</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "48"
              }}>
          <span className="cmp-chart__data-text">48%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">A combination of a single team that manages the design system and a committee that influences decisions.</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "25"
              }}>
          <span className="cmp-chart__data-text">25%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">A cross-team committee is responsible for approving changes.</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "16"
              }}>
          <span className="cmp-chart__data-text">16%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">One individual oversees the design system.</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "6"
              }}>
          <span className="cmp-chart__data-text">6%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">We don’t have a standardized process for this.</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "3"
              }}>
          <span className="cmp-chart__data-text cmp-chart__data-text--outside">3%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Other</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "1"
              }}>
          <span className="cmp-chart__data-text cmp-chart__data-text--outside">1%</span>
        </span>
      </dd>
    </div>
  </dl>
      </div>
      <div className="cmp-answer">
  <p className="cmp-answer__badge">
    <span className="cmp-answer__label">Answered</span>
    <span className="cmp-answer__count">79</span>
  </p>
  <p className="cmp-answer__meta">In-house respondents with a formal or informal maintenance process answered this question.</p>
      </div>
      <h3>{`Takeaway: Launching is just the beginning`}</h3>
      <p>{`Planning ahead for design system maintenance and approval responsibilities evidently contributes to the success of a design system.`}</p>
      <div className="cmp-aside">
  <h3 className="cmp-aside__heading">Does your team struggle with maintaining your design system?</h3>
  <p className="cmp-aside__text">Discover the benefits of <a href="https://sparkbox.com/foundry/automated_testing_for_design_systems">automated testing in the maintenance process</a> and learn how <a href="https://sparkbox.com/foundry/design_systems_teams_are_part_of_the_product">your design system team is part of the product</a>.</p>
      </div>
      <hr></hr>
      <div className="obj-has-float">
        <h2>{`Encouraging Organizational Adoption`}</h2>
        <p>{`Since our first survey, respondents have reported adoption as a challenge, and that theme has continued this year.`}</p>
        <div className="cmp-aside cmp-aside--float cmp-fade-right js-watch" data-animate="cmp-fade-right--animate">
  <p className="cmp-aside__text cmp-aside__text--callout">In-house and agency respondents listed adoption as the top challenge faced in building, using, or maintaining design systems.</p>
        </div>
        <p>{`In-house respondents were asked an open-ended question about the biggest challenges they have faced in building, using, or maintaining their system. Adoption was the top answer, with 21 of 56 respondents mentioning it. When agency respondents were asked a similar question, the top reason was also adoption, with 8 of the 25 respondents mentioning it.`}</p>
        <p>{`Respondents also highlighted the impact adoption has on a design system’s success.`}</p>
      </div>
      <h3>{`If you feel that your organization’s design system was not successful, what were the primary reasons?`}</h3>
      <div className="cmp-chart-table">
  <table>
    <caption className="util-visually-hidden">If you feel that your organization’s design system was not successful, what were the primary reasons?</caption>
    <tbody>
      <tr>
        <th>Staffing difficulties</th>
        <td>53%</td>
      </tr>
      <tr>
        <th>Adoption difficulties</th>
        <td>52%</td>
      </tr>
      <tr>
        <th>Maintenance difficulties</th>
        <td>38%</td>
      </tr>
      <tr>
        <th>Lack of an executive champion</th>
        <td>36%</td>
      </tr>
      <tr>
        <th>Funding difficulties</th>
        <td>30%</td>
      </tr>
      <tr>
        <th>Company or departmental changes</th>
        <td>22%</td>
      </tr>
      <tr>
        <th>Other</th>
        <td>25%</td>
      </tr>
    </tbody>
  </table>
      </div>
      <div aria-hidden="true" className="cmp-chart-section cmp-chart-section--is-animated js-watch" data-animate="cmp-chart-section--is-animating">
  <div className="cmp-chart-range">
    <div className="cmp-chart-range__value">0%</div>
    <div className="cmp-chart-range__value">20%</div>
    <div className="cmp-chart-range__value">40%</div>
    <div className="cmp-chart-range__value">60%</div>
  </div>
  <dl className="cmp-chart" style={{
          "-Range": "60"
        }}>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Staffing difficulties</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "53"
              }}>
          <span className="cmp-chart__data-text">53%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Adoption difficulties</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "52"
              }}>
          <span className="cmp-chart__data-text">52%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Maintenance difficulties</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "38"
              }}>
          <span className="cmp-chart__data-text">38%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Lack of an executive champion</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "36"
              }}>
          <span className="cmp-chart__data-text">36%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Funding difficulties</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "30"
              }}>
          <span className="cmp-chart__data-text">30%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Company or departmental changes</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "22"
              }}>
          <span className="cmp-chart__data-text">22%</span>
        </span>
      </dd>
    </div>
    <div className="cmp-chart__group">
      <dt className="cmp-chart__title">Other</dt>
      <dd className="cmp-chart__range">
        <span className="cmp-chart__data" style={{
                "-Val": "25"
              }}>
          <span className="cmp-chart__data-text">25%</span>
        </span>
      </dd>
    </div>
  </dl>
      </div>
      <div className="cmp-answer">
  <p className="cmp-answer__badge">
    <span className="cmp-answer__label">Answered</span>
    <span className="cmp-answer__count">73</span>
  </p>
  <p className="cmp-answer__meta">
    Respondents were asked to select all that apply.
  </p>
      </div>
      <p>{`When in-house respondents were asked from a list of six options for the primary reason their design system is not successful, 52% of respondents said adoption difficulties, placing it second (just behind staffing difficulties).`}</p>
      <div className="cmp-aside">
  <h3 className="cmp-aside__heading">Check this out!</h3>
  <p className="cmp-aside__text cmp-aside__text--large cmp-aside__text--no-margin">Just 41% of in-house respondents said that they were actually tracking the adoption of their design system.</p>
      </div>
      <h3>{`Takeaway: Adoption is a priority`}</h3>
      <p>{`Perhaps recognizing the importance of adoption, just under half of the in-house respondents said that improving design system adoption is a top priority for this upcoming year.`}</p>
  <div className="cmp-aside">
    <h3 className="cmp-aside__heading">Is your team working on adoption? </h3>
    <p className="cmp-aside__text">Learn how to <a href="https://sparkbox.com/foundry/versioning_design_systems_components_with_component_scorecards">use scorecards to provide transparency and guidance for subscribers</a> or learn how to <a href="https://sparkbox.com/foundry/keeping_subscribers_engaged_in_your_design_system">overcome disengagement and maintain subscriber loyalty</a>.</p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      